var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board__list"
  }, [_c('div', {
    staticClass: "mb-20 mb-lg-30"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('board-filter', {
    attrs: {
      "children": _vm.category.children
    },
    on: {
      "apply": _vm.setFilter
    }
  })], 1)], 1)], 1), _vm.page === 1 ? _c('v-row', {
    staticClass: "my-n10 my-lg-n30"
  }, _vm._l(_vm.hotBoards, function (board) {
    return _c('v-col', {
      key: board._id,
      staticClass: "py-10 py-lg-30",
      attrs: {
        "cols": "6",
        "md": "3"
      },
      on: {
        "click": function ($event) {
          return _vm.move(board);
        }
      }
    }, [_c('board-item-primary', {
      attrs: {
        "board": board,
        "tag": true,
        "hot": true
      }
    })], 1);
  }), 1) : _vm._e(), _c('div', {
    staticClass: "mt-10 mt-lg-30"
  }, [_c('v-row', {
    staticClass: "my-n10 my-lg-n30"
  }, _vm._l(_vm.boards, function (board) {
    return _c('v-col', {
      key: board._id,
      staticClass: "py-10 py-lg-30",
      attrs: {
        "cols": "6",
        "md": "3"
      },
      on: {
        "click": function ($event) {
          return _vm.move(board);
        }
      }
    }, [_c('board-item-primary', {
      attrs: {
        "board": board,
        "tag": true
      }
    })], 1);
  }), 1)], 1), _c('v-divider', {
    staticClass: "mt-30 mt-lg-60 mb-20 mb-lg-40"
  }), _c('v-row', {
    staticClass: "row--x-small search--primary"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "v-input--small w-100 w-md-120px",
    attrs: {
      "items": _vm.searchKeys,
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "9",
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "v-input--small w-100 w-md-240px",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "검색어를 입력하세요."
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3",
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-100 w-100 min-w-md-60px",
    attrs: {
      "color": "grey-6"
    },
    on: {
      "click": function ($event) {
        return _vm.search(false);
      }
    }
  }, [_c('span', {
    staticClass: "white--text font-size-14"
  }, [_vm._v("검색")])])], 1)], 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }